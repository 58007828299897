import {
  IMagentoItem,
  ISearchResult,
  MagentoItem,
  MagentoServiceHandler,
  ProcessType,
  STAFF_PICK_RECOMMENDATION_ID,
  ThreadHandler,
  WD,
  IElasticSearchOptions,
  sleep,
  isEqual,
  IMagentoStore,
  RestServiceHandler,
  hydrateStore,
  WINE_PAGE_SIZE,
  getPlatform,
  Wine,
} from '..';
import {Logger} from '../Handler/Logger';
import {ProductAttributeMap} from '../Model/ProductAttributeMap';
import {AnalyticHandler} from '../Handler/AnalyticHandler';
import {splitListToChunks, jsonClone, flattenArray, removeEmptyValues} from '../Helper/Helper';

const BROKEN_ITEMS: string[] = [];

export class ProductActions {
  static skuListPages: {[key: string]: number} = {};

  static get threadHandler(): ThreadHandler {
    return WD.store.threadHandler;
  }

  static async visionSearch(imageData: string): Promise<string[]> {
    const r = await WD.store.service.visionSearch(imageData);
    ProductActions.addToAllItems(r.items);
    return r.items.map((i) => i.sku);
  }
  static async loadItemsSync(skus: string[]) {
    const needFetchSkus = skus
      .filter((sku) => WD.isItemLoaded(sku) === false)
      .filter((sku) => BROKEN_ITEMS.indexOf(sku) < 0);
    if (needFetchSkus.length > 0) {
      const chunks = splitListToChunks(needFetchSkus, 30);
      const promises: Promise<any>[] = chunks.map(async (chunk) => {
        Logger.logInfo('ProductAction', 'Loading Items', chunk);
        const result = await WD.store.service.getItems(chunk);
        skus.forEach((sku) => {
          if (!result.items.find((i) => i.sku === sku)) {
            console.log(sku, 'is broken');
            BROKEN_ITEMS.push(sku);
          }
        });
        await this.addToAllItems(result.items);
      });
      await Promise.all(promises);
    }
    return skus
      .map((sku) => {
        return WD.store.itemMap[sku];
      })
      .filter((i) => i);
  }
  static async loadItem(sku: string) {
    const itemFromMap = WD.store.itemMap[sku];
    if (itemFromMap) {
      return itemFromMap;
    }
    if (sku && sku.indexOf('WD') >= 0) {
      const processKey = 'LOAD_ITEM_' + sku;
      const processType = ProcessType.DYNAMIC(processKey);
      if (this.threadHandler.isProcessQueueEmpty(processType)) {
        return await this.threadHandler.runSequential(processType, async () => {
          const r = await WD.store.service.getProductInfo(sku);
          await this.addToAllItems([r]);
          return WD.store.itemMap[sku];
        });
      } else {
        return await this.threadHandler.runSequential(processType, async () => {
          return WD.store.itemMap[sku];
        });
      }
    }
    return null;
  }
  static async addToAllItems(magentoItems: IMagentoItem[], service: MagentoServiceHandler = WD.store.service) {
    await this.threadHandler.runSequential(ProcessType.ALL_ITEM, async () => {
      WD.store.itemMap = WD.addToItemMap(magentoItems, WD.store.itemMap);
    });
  }
  static async searchProduct(
    searchOptions: IElasticSearchOptions,
    {isGlobal = false, page = 1, pageSize = WINE_PAGE_SIZE},
  ) {
    if (!searchOptions.keywords) {
      searchOptions.keywords = '*';
    } else {
      searchOptions.keywords = searchOptions.keywords.trim();
    }
    const oldOptions = jsonClone(WD.store.globalSearchOptions);
    if (isGlobal) {
      const optionChanged = !isEqual(oldOptions, jsonClone(searchOptions));
      const samePage = WD.store.globalSearchPage === page;
      if (!optionChanged && samePage) {
        Logger.logInfo('SEARCH', 'NO_CHANGE');

        return [];
      } else {
        Logger.logInfo('SEARCH', 'UPDATE', {...oldOptions, page: WD.store.globalSearchPage}, '->', {
          ...searchOptions,
          page,
        });
        WD.store.globalSearchOptions = {...searchOptions};
      }
    }
    WD.store.isLoading = true;
    const items = await this.threadHandler.runSequential(
      ProcessType.DYNAMIC('SEARCH'),
      async () => {
        if (isGlobal) {
          if (!isEqual(oldOptions, searchOptions) || page === 1) {
            WD.store.globalSearchItems = [];
            WD.store.globalSearchItemTotal = 0;
            AnalyticHandler.onEvent({
              category: 'PRODUCT',
              action: 'SEARCH',
              label: JSON.stringify(searchOptions),
            });
          }
          WD.store.globalSearchIsLoading = true;
          WD.store.globalSearchPage = page;
        }
        console.log('[ELASTIC_SEARCH]', searchOptions);
        const result = await WD.store.service.elasticSearch(searchOptions, page, pageSize);
        await this.addToAllItems(result.items);
        const items = result.items.map((i) => i.sku);
        if (isGlobal) {
          if (result.items.length > 0) {
            WD.store.globalSearchFilterBuckets = result.aggregations.buckets;
            if (!isEqual(jsonClone(WD.store.globalSearchOptions), searchOptions) || page === 1) {
              WD.store.globalSearchItems = items;
            } else {
              WD.store.globalSearchItems = [...WD.store.globalSearchItems, ...items];
            }
            WD.store.globalSearchItemTotal = result.total_count;
          } else {
            if (page === 1) {
              WD.store.globalSearchItemTotal = 0;
            }
          }
          WD.store.globalSearchIsLoading = false;
        }
        return items;
      },
      () => (WD.store.isLoading = false),
    );
    return items;
  }
  static async resetGlobalSearchOption() {
    const options = WD.store.globalSearchOptions;
    await ProductActions.searchProduct(
      {
        keywords: options.keywords,
      },
      {isGlobal: true},
    );
  }
  static async setGlobalSearchOption(key: keyof IElasticSearchOptions, value: any) {
    const options = {...WD.store.globalSearchOptions, [key]: value};
    console.log('new option', options);
    await ProductActions.searchProduct(removeEmptyValues(options), {isGlobal: true});
  }
  private static getNewRestServiceHandler(useCache = true) {
    let restService = undefined;
    if (WD.store.service) {
      restService = WD.store.service.restService.clone(useCache);
    } else {
      restService = new RestServiceHandler('', WD.store, {useCache});
    }
    return new MagentoServiceHandler(restService);
  }
  static async refresh(useCache = true) {
    const service = this.getNewRestServiceHandler(useCache);
    const store = await service.getSSRMainStoreCache();
    // temp to handle blockeddeliverydates structure
    // magento store will not contain blockedDeliveryDates key for the first time when server cache cleared
    if (store.blockedDeliveryDates) {
      store.blockedDeliveryDates = flattenArray(store.blockedDeliveryDates);
    }
    hydrateStore(WD.store, store);
    // await Promise.all(this.getPreloadDataPromises(service));
  }
  static getPreloadDataPromises(service: MagentoServiceHandler) {
    const promises: Promise<any>[] = [];

    const addToPromises = (name: string, task: () => Promise<any>) => {
      promises.push(
        (async () => {
          try {
            await task();
          } catch (e) {
            console.warn('Magento Store Failed to complete task: ' + name + ', ' + new Error(e));
          }
        })(),
      );
    };

    addToPromises('get recommendations', async () => {
      WD.store.recommendations = await service.getRecommendations();
    });

    addToPromises('get product attributes', async () => {
      await this.loadProductAttributes(WD.store, service);
    });

    addToPromises('get getSalesProducts', async () => {
      const result = await service.getSalesProducts(WINE_PAGE_SIZE);
      await ProductActions.addToAllItems(result.items, service);
      WD.store.saleProducts = result.items.map((i) => i.sku).sort(() => Math.random() - 0.5);
      WD.store.saleProductsCount = result.total_count;
    });

    addToPromises('get homeProducts', async () => {
      const result = await service.getHomeProducts(WINE_PAGE_SIZE);
      await ProductActions.addToAllItems(result.items, service);
      WD.store.homeProducts = result.items.map((i) => i.sku);
      WD.store.homeProductsCount = result.total_count;
    });

    addToPromises('get getLatestProducts', async () => {
      const result = await service.getLatestProducts(WINE_PAGE_SIZE);
      await ProductActions.addToAllItems(result.items, service);
      WD.store.latestProducts = result.items.map((i) => i.sku);
      WD.store.latestProductsCount = result.total_count;
    });

    addToPromises('get getSpiritProducts', async () => {
      const result = await service.getSpiritProducts(WINE_PAGE_SIZE);
      await ProductActions.addToAllItems(result.items, service);
      WD.store.spiritProducts = result.items.map((i) => i.sku).sort(() => Math.random() - 0.5);
      WD.store.spiritProductsCount = result.total_count;
    });

    addToPromises('get Organic Wines', async () => {
      const result = await service.getOrganicProducts(WINE_PAGE_SIZE);
      await ProductActions.addToAllItems(result.items, service);
      WD.store.organicProducts = result.items.map((i) => i.sku).sort(() => Math.random() - 0.5);
      WD.store.organicProductsCount = result.total_count;
    });

    // Added back per NWD-3227
    addToPromises('get getNextDayDeliveryProducts', async () => {
      const result = await service.getNextDayDeliveryProducts(WINE_PAGE_SIZE);
      await ProductActions.addToAllItems(result.items, service);
      WD.store.nextDayDeliveries = result.items.map((i) => i.sku).sort(() => Math.random() - 0.5);
      WD.store.nextDayDeliveriesCount = result.total_count;
    });

    addToPromises('get getExpressProducts', async () => {
      const result = await service.getExpressProducts(WINE_PAGE_SIZE);
      await ProductActions.addToAllItems(result.items, service);
      WD.store.expressProducts = result.items.map((i) => i.sku).sort(() => Math.random() - 0.5);
      WD.store.expressProductsCount = result.total_count;
      WD.store.expressEarliestDeliveryDate = result.earliest_delivery_date;
    });

    addToPromises('get gifts', async () => {
      const giftsJson = await service.getGifts();
      WD.store.gifts = WD.toMagentoItems(giftsJson.items);
    });

    addToPromises('get getTimeSlots', async () => {
      await this.updateTimeSlots();
      //     console.log(await WD.store.service.getTimeSlots())
    });

    addToPromises('get getExpressCutOffTime', async () => {
      await this.updateExpressCutOffTime();
      //     console.log(await WD.store.service.getTimeSlots())
    });

    addToPromises('get staff picks', async () => {
      const result = await service.getRecommendation(STAFF_PICK_RECOMMENDATION_ID);
      const searchResult = await service.getItems(result.products.map((i) => i.sku));
      await ProductActions.addToAllItems(searchResult.items, service);
      WD.store.staffPicks = searchResult.items.map((i) => i.sku).sort(() => Math.random() - 0.5);
    });

    // removed AWS personal recommendation
    // addToPromises('get personal recommendation', async () => {
    //   await ProductActions.refreshPersonalRecommendation();
    // });
    return promises;
  }
  static async getTimeSlots() {
    const result = await WD.store.service.getTimeSlots();
    // temp to handle blockeddeliverydates structure
    if (result.blocked_delivery_dates) {
      result.blocked_delivery_dates = flattenArray(result.blocked_delivery_dates);
    }
    return result;
  }
  static async updateTimeSlots() {
    const result = await WD.store.service.getTimeSlots();
    WD.store.timeSlots = result.time_slot;
    WD.store.blockedDeliveryDates = result.blocked_delivery_dates || [];
    WD.store.blockedDeliveryTimeSlots = result.blocked_delivery_slots || [];
  }
  static async updateExpressCutOffTime(): Promise<void> {
    const result = await WD.store.service.getExpressCutOffTime();
    WD.store.expressCutOffTime = result.wd_express_cutoff;
  }
  static async loadProductAttributes(store: IMagentoStore, service: MagentoServiceHandler) {
    const result = await service.getCombinedProductAttributes();
    store.countries = new ProductAttributeMap(result.countries);
    store.grapeVarieties = new ProductAttributeMap(result.grape_varieties);
    store.productTypes = new ProductAttributeMap(result.types);
    store.regions = new ProductAttributeMap(result.regions);
  }
  static getSkuListPage(skuListName: string): number {
    if (ProductActions.skuListPages[skuListName] === undefined) {
      ProductActions.skuListPages[skuListName] = 1;
    }
    return ProductActions.skuListPages[skuListName];
  }
  static setSkuListPage(skuListName: string, page: number) {
    ProductActions.skuListPages[skuListName] = page;
  }
  static async loadPage(
    skuListName: string,
    loadPage: (pageSize: number, page: number) => Promise<ISearchResult>,
    randomize = false,
  ) {
    const process = ProcessType.PAGE_LOAD(skuListName.toUpperCase());
    const currentPage = ProductActions.getSkuListPage(skuListName);
    if (this.threadHandler.isProcessQueueBusy(process) === false) {
      await this.threadHandler.runSequential(process, async () => {
        if (currentPage > 0) {
          const nextPage = currentPage + 1;
          // WD.store.throwableResponse.onToastNormal("Loading More..");
          const r = await loadPage(WINE_PAGE_SIZE, nextPage);
          const items = r.items;
          if (randomize) items.sort(() => Math.random() - 0.5);
          if (r.total_count) WD.store[skuListName + 'Count'] = r.total_count;
          await ProductActions.addToAllItems(items);
          const list = WD.store[skuListName];
          const newItems = [];
          items.forEach((i) => {
            if (list.indexOf(i.sku) < 0) {
              newItems.push(i.sku);
            }
          });
          if (newItems.length > 0) {
            const newList = [...list, ...newItems];
            WD.store[skuListName] = newList;
            ProductActions.setSkuListPage(skuListName, nextPage);
          } else {
            ProductActions.setSkuListPage(skuListName, -1);
          }
        } else {
          WD.store.throwableResponse.onToastNormal('You have reached end of list.');
          await sleep(3000);
        }
      });
    }
  }
  static async loadSalesProductPage() {
    return ProductActions.loadPage(
      'saleProducts',
      async (pageSize: number, page: number) => await WD.store.service.getSalesProducts(pageSize, page),
      true,
    );
  }
  static async loadHomeProductsPage() {
    return ProductActions.loadPage(
      'homeProducts',
      async (pageSize: number, page: number) => await WD.store.service.getHomeProducts(pageSize, page),
    );
  }
  static async loadLatestProductsPage() {
    return ProductActions.loadPage(
      'latestProducts',
      async (pageSize: number, page: number) => await WD.store.service.getLatestProducts(pageSize, page),
    );
  }
  static async loadSpiritProductsPage() {
    return ProductActions.loadPage(
      'spiritProducts',
      async (pageSize: number, page: number) => await WD.store.service.getSpiritProducts(pageSize, page),
      true,
    );
  }
  static async loadOrganicProductsPage() {
    return ProductActions.loadPage(
      'organicProducts',
      async (pageSize: number, page: number) => await WD.store.service.getOrganicProducts(pageSize, page),
      true,
    );
  }
  static async loadNextDayDeliveryPage() {
    return ProductActions.loadPage(
      'nextDayDeliveries',
      async (pageSize: number, page: number) => await WD.store.service.getNextDayDeliveryProducts(pageSize, page),
      true,
    );
  }
  static async loadExpressProductsPage() {
    return ProductActions.loadPage(
      'expressProducts',
      async (pageSize: number, page: number) => await WD.store.service.getExpressProducts(pageSize, page),
      true,
    );
  }

  static async requestForWine(sku: string) {
    try {
      const r = await WD.store.service.requestForWine(sku);
      WD.store.requestedWineSkus = [...WD.store.requestedWineSkus, sku];
    } catch (e) {
      WD.store.throwableResponse.onError(e + '');
    }
  }
  static async refreshRequestedWines() {
    const r = await WD.store.service.getRequestedWine();
    WD.store.requestedWineSkus = r;
    return r;
  }
  static async refreshAvailableSearchFilters() {
    const r = await WD.store.service.elasticSearch({keywords: '*'});
    const filters = WD.getSearchFilters(r.aggregations.buckets);
    WD.store.availableSearchFilters = filters;
    return filters;
  }
  static async refreshPersonalRecommendation() {
    let id = 'GUEST';
    if (WD.store.customer) {
      id = WD.store.customer.email;
    }
    const r = await WD.store.service.getPersonalRecommendation(id);
    await this.addToAllItems(r.items);
    WD.store.personalRecommendations = r.items.map((i) => i.sku);
  }
  static getPersonalRecommendations(): Wine[] {
    return WD.getItems(WD.store.personalRecommendations) as Wine[];
  }
}
